import { useState, useEffect, FunctionComponent } from 'react';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Nav from 'react-bootstrap/Nav';
import './style.scss';

export enum PaymentType {
  Local = 'cash',
  Inter = 'credit'
}
enum SourceType {
  Local = 'local',
  Inter = 'inter'
}

const content = {
  th: {
    title: ['บริษัทรถเช่ารายใหญ่', 'บริษัทรถเช่าท้องถิ่น'],
    subTitle: [
      'เช่ารถโดย ใช้บัตรเครดิต',
      <>
        เช่ารถโดย <b>ไม่</b>ต้องใช้บัตรเครดิต
      </>
    ]
  },
  en: {
    title: ['Interbrand Car Rental Companies', 'Local Car Rental Companies'],
    subTitle: ['Credit card required.', 'Rent a vehicle without requiring a credit card.']
  }
};

const renderTitle = (source: string, prefix: string) => {
  const text = content[prefix];
  return (
    <div className="help-type-tab">
      <div className={`badge badge-${source} badge--help-type px-2 py-0 mb-2 mb-lg-0`}>
        <i className={`icon-${source}`} />
        {source.toUpperCase()}
      </div>
      <div className="header">
        {source == SourceType.Inter ? (
          <>
            {text.title[0]} <br />
            <span>{text.subTitle[0]}</span>
          </>
        ) : (
          <>
            {text.title[1]} <br />
            <span>{text.subTitle[1]}</span>
          </>
        )}
      </div>
    </div>
  );
};
export const LocalTab: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  return <TabPane eventKey={PaymentType.Local}>{children}</TabPane>;
};
export const InterTab: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  return <TabPane eventKey={PaymentType.Inter}>{children}</TabPane>;
};

interface HelpTabsProps {
  children: (el: { LocalTab: typeof LocalTab; InterTab: typeof InterTab }) => React.ReactNode;
  modifyTitleLocal?: React.ReactNode | JSX.Element;
  modifyTitleInter?: React.ReactNode | JSX.Element;
  defaultTab?: PaymentType;
  disableHash?: boolean;
  prefix: string;
  onExpand?: (paymentType: PaymentType) => void;
}

const HelpTabs: FunctionComponent<HelpTabsProps> = ({
  children,
  defaultTab,
  modifyTitleLocal,
  modifyTitleInter,
  disableHash,
  prefix,
  onExpand
}) => {
  const [key, setKey] = useState<PaymentType>(defaultTab ? defaultTab : PaymentType.Local);
  const findSource = (key: string) => (key == PaymentType.Inter ? PaymentType.Inter : PaymentType.Local);
  useEffect(() => {
    if (!window) return;
    if (disableHash) return;
    if (window.location.hash !== '') return setKey(findSource(window.location.hash.split('#')[1]));
    window.location.hash = key;
  });
  return (
    <div className="help-type-box">
      <TabContainer
        id="help-type"
        activeKey={key}
        onSelect={(k) => {
          if (!k) return;
          setKey(findSource(k));
          onExpand && onExpand(findSource(k));
        }}
      >
        <Nav variant="pills" className="flex-row flex-nowrap flex-lg-wrap">
          <Nav.Item className="nav-item--help">
            <Nav.Link
              href={`#${disableHash ? '' : PaymentType.Local}`}
              eventKey={PaymentType.Local}
              className="nav-link--help"
            >
              {modifyTitleLocal ? modifyTitleLocal : renderTitle(SourceType.Local, prefix)}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item--help">
            <Nav.Link
              href={`#${disableHash ? '' : PaymentType.Inter}`}
              eventKey={PaymentType.Inter}
              className="nav-link--help"
            >
              {modifyTitleInter ? modifyTitleInter : renderTitle(SourceType.Inter, prefix)}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <TabContent>
          {children({
            LocalTab: LocalTab,
            InterTab: InterTab
          })}
        </TabContent>
      </TabContainer>
    </div>
  );
};

export default HelpTabs;
