import { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';

import HelpTabs from '@pages/help/components/tabs';

import { trackHowToCancelPaymentType, trackHowToCancelContact, HowToCancelContactType } from './tracking';

import {
  entitleInterContent,
  termInterTable,
  termText,
  termInterContent,
  conclusionInterContent,
  noShowContent,
  noShowConclusionContent
} from './contentsInter';
import type { DefaultProps } from '../interface';

import './how-to-cancel.scss';

const content = {
  th: {
    header: ['การยกเลิกการจอง', 'การเปลี่ยนแปลงการจอง'],
    content: {
      bigbrand: {
        description: [
          [
            `ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub หรือส่งมา ที่ <a href="mailto:support@drivehub.co">support@drivehub.co</a> 
            หรือ Line@ : <a rel="nofollow" href="https://lin.ee/axOqULu">@drivehub</a>`,
            'เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้'
          ],
          '*ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป และการคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป',
          [
            'ท่านสามารถทำการเปลี่ยนแปลงการจองได้ เพียงแจ้งข้อมูลที่ต้องการเปลี่ยนแปลงกับทางเจ้าหน้าที่ Drivehub ผ่าน 2 ช่องทาง',
            '',
            '02-038-5222',
            'Official Line@ :',
            '@drivehub',
            'ตลอดช่วงเวลา 08:00 - 22:00 น.',
            '*หลังจากที่ท่านแจ้งข้อมูลเปลี่ยนแปลงแล้ว โปรดรอการติดต่อกลับเพื่อยืนยันการเปลี่ยนแปลง '
          ]
        ],
        subContent: [
          {
            header: 'กรณีที่ไม่แสดงตัว',
            text: [
              'ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อ',
              'ท่านต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด',
              'ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้',
              'ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ',
              'ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ',
              '*ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า'
            ]
          }
        ]
      },
      original: {
        description: [
          [
            'กรณียกเลิก 72 ชั่วโมงก่อนรับรถ ท่านจะได้รับค่าล็อคคิวรถคืน ทั้งนี้เงื่อนไขการคืนเงินบริษัทรถเช่าอาจมีการเปลี่ยนแปลง ท่านสามารถสอบถามข้อมูลกับบริษัทรถเช่าได้โดยตรง',
            'ท่านสามารถแจ้งการยกเลิกการจองได้ 2 ช่องทาง',
            'บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
            'เจ้าหน้าที่ Drivehub ที่เบอร์',
            '02-038-5222',
            'หรือ Line Id: ',
            '@Drivehub'
          ],
          [
            'ท่านสามารถแจ้งการเปลี่ยนแปลงได้ทั้ง 2 ช่องทาง',
            'บริษัทรถเช่าที่ท่านทำการจองเข้ามา',
            'เจ้าหน้าที่ Drivehub ที่เบอร์',
            '02-038-5222',
            'หรือ Line Id: ',
            '@Drivehub',
            'ค่าใช้จ่าย และรถที่ท่านเลือก อาจจะเปลี่ยนแปลงได้ทั้งนี้เจ้าหน้าที่จะตรวจสอบข้อมูลให้ และแจ้งกลับ'
          ]
        ]
      }
    }
  },
  en: {
    header: ['Booking Cancellation:', 'Booking Changes:'],
    content: {
      bigbrand: {
        description: [
          [
            `ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub หรือส่งมา ที่ <a href="mailto:support@drivehub.co">support@drivehub.co</a> 
            หรือ Line@ : <a rel="nofollow" href="https://lin.ee/axOqULu">@drivehub</a>`,
            'เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้',
            'หากมีการยกเลิก 48 ชั่วโมง ก่อนกำหนดรับรถ ท่านจะได้รับเงินคืนเต็มจำนวน (โดยต้องแจ้งยกเลิกในวัน และเวลาทำการ 08.00-20.00 น.)',
            'กรณีที่จองเพื่อรับรถด่วน (ภายใน 48 ชั่วโมง) หากมีการยกเลิก ทางบริษัทขอสงวนสิทธิ์คืนเงินทุกกรณี',
            'กรณีที่จองราคาพิเศษ โปรโมชั่น หรือมีการใช้ส่วนลด หากมีการยกเลิก ทางบริษัทขอสงวนสิทธิ์คืนเงินทุกกรณี',
            'กรณีที่อยู่ในช่วงระยะเวลาการใช้รถ และต้องการคืนรถก่อนกำหนด ทางบริษัทขอสงวนสิทธิ์คืนเงินสำหรับวันใช้งานที่คงเหลือทุกกรณี',
            'ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ / หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า และไม่ได้รับเงินคืน'
          ],
          '*ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป และการคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป',
          [
            'You can notify us about  the desired modifications through two channels, available from 08:00 AM to 10:00 PM.',
            'The car rental company with which you made the booking.',
            'Drivehub customer support at phone number',
            '02-038-5222',
            'or Line ID:',
            '@drivehub',
            '*After you have provided the updated information, please await a follow-up contact for confirmation of the changes. '
          ]
        ],
        subContent: [
          {
            header: 'กรณีที่ไม่แสดงตัว',
            text: [
              'ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อ',
              'ท่านต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด',
              'ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้',
              'ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ',
              'ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ',
              '*ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า'
            ]
          }
        ]
      },
      original: {
        description: [
          [
            'If you cancel less than 72 hours before the scheduled car pickup, a rebooking fee will be applicable upon cancellation. Please note that the refund policies of car rental companies may vary. For precise details, you can directly inquire with the rental company.',
            'You can notify us about the cancellation through two channels:',
            'The car rental company with which you made the booking.',
            'Drivehub customer support at phone number',
            '02-038-5222',
            'or Line Id: ',
            '@Drivehub'
          ],
          [
            'You can notify us about the cancellation through two channels:',
            'The car rental company with which you made the booking.',
            'Drivehub customer support at phone number',
            '02-038-5222',
            'or Line Id: ',
            '@Drivehub',
            'Please be aware that there could be adjustments in costs and the availability of the chosen car model. Our team will review the information and provide you with prompt updates.'
          ]
        ]
      }
    }
  }
};
const HowToCancel: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = content[prefix];
  const textOriginal = text.content.original.description;
  const textBigbrand = text.content.bigbrand.description;

  const interEntitle = entitleInterContent[prefix];
  const interTermTable = termInterTable[prefix];
  const interTermText = termText[prefix];
  const interTermContent = termInterContent[prefix];
  const interConclusion = conclusionInterContent[prefix];
  const interNoShowContent = noShowContent[prefix];
  const interNoShowConclusionContent = noShowConclusionContent[prefix];

  return (
    <div className="how-to-cancel">
      <HelpTabs prefix={prefix} onExpand={(paymentType) => trackHowToCancelPaymentType(paymentType, 'howtocancel')}>
        {({ LocalTab, InterTab }) => {
          return (
            <>
              <LocalTab>
                <div className="mt-4">
                  <div className="mb-2">
                    <h2>{text.header[0]}</h2>
                    <p>{textOriginal[0][0]}</p>
                    <p>{textOriginal[0][1]}</p>
                    <ol>
                      <li>
                        <p>{textOriginal[0][2]}</p>
                      </li>
                      <li>
                        {textOriginal[0][3]}{' '}
                        <span>
                          <a
                            rel="noreferrer"
                            href="tel:+6620385222"
                            onClick={() => trackHowToCancelContact(HowToCancelContactType.CallCenter, 'cancel')}
                          >
                            {textOriginal[0][4]}{' '}
                          </a>
                        </span>
                        {textOriginal[0][5]}{' '}
                        <span>
                          <a
                            rel="nofollow"
                            href="https://lin.ee/axOqULu"
                            onClick={() => trackHowToCancelContact(HowToCancelContactType.Line, 'cancel')}
                          >
                            {textOriginal[0][6]}
                          </a>
                        </span>
                      </li>
                    </ol>
                  </div>
                  <div>
                    <h2>{text.header[1]}</h2>
                    <p>{textOriginal[1][0]}</p>
                    <ol className="mb-0">
                      <li>
                        <p>{textOriginal[1][1]}</p>
                      </li>
                      <li>
                        {textOriginal[1][2]}{' '}
                        <span>
                          <a
                            rel="noreferrer"
                            // href="tel:+6620385222"
                            href="#"
                            onClick={() => trackHowToCancelContact(HowToCancelContactType.CallCenter, 'edit')}
                          >
                            {textOriginal[1][3]}{' '}
                          </a>
                        </span>
                        {textOriginal[1][4]}{' '}
                        <span>
                          <a
                            rel="nofollow"
                            // href="https://lin.ee/axOqULu"
                            href="#"
                            onClick={() => trackHowToCancelContact(HowToCancelContactType.Line, 'edit')}
                          >
                            {textOriginal[1][5]}
                          </a>
                        </span>
                      </li>
                    </ol>

                    <p>{textOriginal[1][6]}</p>
                  </div>
                </div>
              </LocalTab>
              <InterTab>
                <div className="mt-4">
                  <div className="mb-2">
                    <h2>{interEntitle.title}</h2>
                    <p>{interEntitle.data[0]}</p>
                    <div>
                      <p>{interTermTable.title}</p>
                      {interTermTable.table && (
                        <Table className="term-table term-table__table" bordered>
                          <tbody>
                            <tr className="term__title">
                              <th colSpan={5}>
                                <span>{interTermTable.table.title}</span>
                              </th>
                            </tr>
                            <tr>
                              {(interTermTable.table.header ?? []).map((h, i) => (
                                <th key={i}>{h}</th>
                              ))}
                            </tr>
                            {interTermTable.table.data.map((d, i) => {
                              return (
                                <tr key={`d-${i}`}>
                                  {d.map((r, i) => {
                                    const opts = r.options;
                                    let value = r.value;
                                    let refund = true;

                                    if (typeof r.value === 'boolean') {
                                      value = r.value ? interTermText.refund : interTermText.nonRefund;
                                      refund = r.value;
                                    }
                                    return (
                                      <td
                                        {...(opts
                                          ? {
                                              colSpan: opts.colSpan,
                                              rowSpan: opts.rowSpan
                                            }
                                          : {})}
                                        key={`d-${i}-opt-${i}`}
                                        className={refund ? 'refund' : 'non-refund'}
                                        style={opts ? { paddingTop: opts.paddingTop } : {}}
                                      >
                                        {value}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      )}
                    </div>
                    <ul>
                      {interTermContent.data.map((elem, i) => {
                        return <li key={i}>{elem}</li>;
                      })}
                    </ul>
                    {interConclusion.data.map((elem, i) => {
                      return <p key={i}>{elem}</p>;
                    })}

                    <p>{interNoShowContent.title}</p>
                    <ul>
                      {interNoShowContent.data.map((elem, i) => {
                        return <li key={i}>{elem}</li>;
                      })}
                    </ul>
                    {interNoShowConclusionContent.data.map((elem, i) => {
                      return <p key={i}>{elem}</p>;
                    })}
                  </div>
                  <div>
                    <h2>{text.header[1]}</h2>
                    <p>{textBigbrand[2][0]}</p>
                    <ol className="mb-0">
                      <li>
                        <p>
                          {prefix === 'en' ? (
                            <span>{textBigbrand[2][1]}</span>
                          ) : (
                            <span>
                              <a
                                rel="noreferrer"
                                href="tel:+6620385222"
                                onClick={() => trackHowToCancelContact(HowToCancelContactType.CallCenter, 'edit')}
                              >
                                {textBigbrand[2][2]}
                              </a>
                            </span>
                          )}
                        </p>
                      </li>
                      <li>
                        {prefix === 'en' ? (
                          <div>
                            <span>{textBigbrand[2][2]} </span>
                            <span>
                              <a
                                rel="noreferrer"
                                href="tel:+6620385222"
                                onClick={() => trackHowToCancelContact(HowToCancelContactType.CallCenter, 'edit')}
                              >
                                {textBigbrand[2][3]}{' '}
                              </a>
                            </span>
                            <span>{textBigbrand[2][4]} </span>
                            <span>
                              <a
                                rel="nofollow"
                                href="https://lin.ee/axOqULu"
                                onClick={() => trackHowToCancelContact(HowToCancelContactType.Line, 'edit')}
                              >
                                {textBigbrand[2][5]}
                              </a>
                            </span>
                          </div>
                        ) : (
                          <p>
                            {textBigbrand[2][3]}{' '}
                            <span>
                              <a
                                rel="nofollow"
                                href="https://lin.ee/axOqULu"
                                onClick={() => trackHowToCancelContact(HowToCancelContactType.Line, 'edit')}
                              >
                                {textBigbrand[2][4]}
                              </a>
                            </span>
                          </p>
                        )}
                      </li>
                    </ol>
                  </div>
                  {prefix !== 'en' && <p>{textBigbrand[2][5]}</p>}
                  <p>
                    <strong style={{ fontSize: 14 }}>{textBigbrand[2][6]}</strong>
                  </p>
                </div>
              </InterTab>
            </>
          );
        }}
      </HelpTabs>
    </div>
  );
};

export default HowToCancel;
