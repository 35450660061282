export const entitleInterContent: ContentType = {
  th: {
    title: 'การยกเลิกการจอง',
    data: [
      <>
        ท่านสามารถแจ้งการยกเลิกโดยตอบกลับอีเมลล์การจองที่ท่านได้รับจาก Drivehub หรือส่งมา ที่{' '}
        <a href="mailto:support@drivehub.co">support@drivehub.co</a>
        หรือ Line@ :{' '}
        <a rel="nofollow" href="https://lin.ee/axOqULu">
          @drivehub
        </a>
      </>
    ]
  },
  en: {
    title: 'Booking Cancellation.',
    data: [
      <>
        You can inform us of the cancellation by replying to the booking confirmation email you received from Drivehub
        or by sending a message to <a href="mailto:support@drivehub.co">support@drivehub.co </a>
        or via Line@:{' '}
        <a rel="nofollow" href="https://lin.ee/axOqULu">
          @drivehub
        </a>
      </>
    ]
  }
};
export const termText = {
  th: {
    refund: (
      <>
        ได้รับเงินคืนเต็ม <br />
        จำนวน
      </>
    ),
    nonRefund: 'ไม่ได้รับเงินคืน'
  },
  en: {
    refund: (
      <>
        Full refund will be <br />
        provided.
      </>
    ),
    nonRefund: 'No refund'
  }
};
const interDealer = [
  [
    {
      value: 'Bizcar'
    },
    {
      value: true,
      options: {
        colSpan: 1,
        rowSpan: 12,
        paddingTop: 35
      }
    },
    {
      value: false,
      options: {
        colSpan: 2,
        rowSpan: 1
      }
    },
    {
      value: false,
      options: {
        colSpan: 1,
        rowSpan: 12,
        paddingTop: 15
      }
    }
  ],
  [
    { value: 'Thai Rent a Car' },
    {
      value: true,
      options: {
        colSpan: 1,
        rowSpan: 11
      }
    },
    {
      value: true,
      options: {
        colSpan: 1,
        rowSpan: 11
      }
    }
  ],
  [{ value: 'Hertz' }],
  [{ value: 'Thrifty' }],
  [{ value: 'Dollar Car Rental' }],
  [{ value: 'Sixt' }],
  [{ value: 'Drive Car Rental' }],
  [{ value: 'Chic Car Rent' }],
  [{ value: 'Asap' }],
  [{ value: 'Budget' }],
  [{ value: 'Payless' }],
  [{ value: 'Yesaway' }]
];

export const termInterTable: ContentType = {
  th: {
    title: 'เงื่อนไขในการได้รับเงินคืนในกรณีที่มีการยกเลิกการจองนั้น ให้เป็นไปตามที่ระบุดังต่อไปนี้',
    data: [],
    table: {
      title: 'เงื่อนไขการยกเลิกการจอง ก่อนกำหนดการรับรถ',
      header: [
        <>
          บริษัทรถเช่า <br />
          รายใหญ่
        </>,
        <>
          ก่อน 7 วัน <br />
          <span className="font-weight-normal">(168 ชั่วโมง)</span>
        </>,
        <>
          ก่อน 3 วัน <br />
          <span className="font-weight-normal">(72 ชั่วโมง)</span>
        </>,
        <>
          ก่อน 2 วัน
          <br />
          <span className="font-weight-normal">(48 ชั่วโมง)</span>
        </>,
        <>
          ก่อน 1 วัน
          <br />
          <span className="font-weight-normal">(24 ชั่วโมง)</span>
        </>
      ],
      data: interDealer
    }
  },
  en: {
    title: 'Refund conditions in case of booking cancellation are as follows:',
    data: [],
    table: {
      title: 'Cancellation Terms Prior to Scheduled Car Pickup',
      header: [
        <>
          <span>Interbrand Car</span> <br />
          <span>Rental Companies</span>
        </>,
        <>
          7 Days before <br />
          Car Pickup <br />
          <span className="font-weight-normal">(168 hours)</span>
        </>,
        <>
          3 Days before <br />
          Car Pickup <br />
          <span className="font-weight-normal">(72 hours)</span>
        </>,
        <>
          2 Days before <br />
          Car Pickup <br />
          <span className="font-weight-normal">(48 hours)</span>
        </>,
        <>
          1 Days before <br />
          Car Pickup <br />
          <span className="font-weight-normal">(24 hours)</span>
        </>
      ],
      data: interDealer
    }
  }
};

export const termInterContent: ContentType = {
  th: {
    title: '',
    data: [
      <>
        <b>
          การยกเลิกการจอง จะต้องแจ้งในวัน และเวลาทำการ (08.00-20.00 น.)
          ซึ่งเงื่อนไขการยกเลิกจะขึ้นอยู่กับข้อกำหนดของแต่ละบริษัทรถเช่า
        </b>
      </>,
      'กรณีที่จองเพื่อรับรถด่วน (ภายใน 48 ชั่วโมง) หากมีการยกเลิก ทางบริษัทขอสงวนสิทธิ์คืนเงินทุกกรณี',
      'กรณีที่จองราคาพิเศษ โปรโมชั่น หรือมีการใช้ส่วนลด หากมีการยกเลิก ทางบริษัทขอสงวนสิทธิ์คืนเงินทุกกรณี',
      'กรณีที่อยู่ในช่วงระยะเวลาการใช้รถ และต้องการคืนรถก่อนกำหนด ทางบริษัทขอสงวนสิทธิ์คืนเงินสำหรับวันใช้งานที่คงเหลือทุกกรณี',
      'ในกรณีที่ท่านไม่สามารถไปรับรถได้ในเวลาที่ตกลงกัน / หรือเอกสารที่จำเป็นไม่ครบ / หรือบัตรเครดิตมีวงเงินไม่เพียงพอที่จะเช่ารถ บริษัทรถเช่าจะสงวนสิทธิ์การเช่า และไม่ได้รับเงินคืน'
    ]
  },
  en: {
    title: '',
    data: [
      <>
        <b>
          {`Cancellations must be made during business hours (08:00 AM - 08:00 PM). The cancellation terms will be subject
          to each car rental company's policy.`}
        </b>
      </>,
      'In the event of bookings made for urgent pick-up (within 48 hours), the company reserves the right to retain the rental fee in all cases of cancellation.',
      'For bookings with special rates, promotions, or discounts, the company retains the right to withhold refunds in case of cancellation.',
      'If you are within the rental period and wish to return the vehicle before the scheduled time, the company reserves the right to withhold refunds for the remaining unused days.',
      'Should you be unable to pick up the vehicle at the agreed-upon time, possess incomplete required documents, or have insufficient credit limit on your credit card for the rental, the company retains the right to refuse rental and no refunds will be provided.'
    ]
  }
};

export const conclusionInterContent: ContentType = {
  th: {
    title: '',
    data: [
      <>
        <b>
          *ระบบจะส่งเรื่องขอคืนเงินไปยังธนาคารภายในวันทำการถัดไป และการคืนเงินจะขึ้นอยู่กับนโยบายของแต่ละธนาคาร
          ตามปกติแล้วผู้ถือบัตรจะได้รับเงินคืนในรอบบิลถัดไป
        </b>
      </>
    ]
  },
  en: {
    title: '',
    data: [
      <>
        <b>
          *Drivehub will initiate the refund request to your bank on the following business day, and the actual refund
          process will be subject to the policies of each individual bank. Typically, cardholders receive the refund in
          the next billing cycle.
        </b>
      </>
    ]
  }
};

export const noShowContent: ContentType = {
  th: {
    title: (
      <>
        <p>
          <b>
            <u>กรณีที่ไม่แสดงตัว</u>
          </b>
        </p>
        ท่านไม่สามารถได้รับเงินคืนในกรณีที่ท่านไม่แสดงตัว ซึ่งหมายถึงเมื่อ
      </>
    ),
    data: [
      'ท่านต้องการยกเลิก แต่ไม่ได้แจ้งให้ทางบริษัทหรือ Drivehub.co ทราบก่อนจะเริ่มกำหนดการเช่าตามเวลาที่กำหนด',
      'ไม่สามารถรับรถได้ตามเวลาที่แจ้งไว้',
      'ไม่สามารถให้เอกสารบริษัทรถเช่าร้องขอให้แสดงเมื่อไปรับรถ',
      'ไม่สามารถที่จะให้บัตรเครดิตในชื่อของผู้ขับขี่หลักหรือวงเงินในบัตรเครดิตไม่เพียงพอค่ามัดจำ'
    ]
  },
  en: {
    title: (
      <>
        <p>
          <b>
            <u>In cases of no-show:</u>
          </b>
        </p>
        If you fail to appear for your reservation, you will not be eligible for a refund. This pertains to scenarios
        where:
      </>
    ),
    data: [
      'You intend to cancel but fail to notify either the company or Drivehub.com before the scheduled rental start time.',
      'You are unable to pick up the vehicle at the specified time.',
      'You cannot present the necessary rental documents requested by the car rental company during pickup.',
      `You are unable to provide a credit card in the primary driver's name, or if the credit limit is insufficient for the required deposit.`
    ]
  }
};

export const noShowConclusionContent: ContentType = {
  th: {
    title: '',
    data: [
      <>
        <b>
          *ทั้งนี้บริษัทรถเช่าขอสงวนสิทธิที่จะไม่ส่งมอบรถให้ลูกค้าที่มารับรถไม่ตรงเวลา หรือไม่มีเอกสารในการจองรถเช่า
          หรือวงเงินในบัตรเครดิตไม่เพียงพอสำหรับเป็นค่ามัดจำการเช่า
        </b>
      </>
    ]
  },
  en: {
    title: '',
    data: [
      <>
        <b>
          *The car rental company reserves the right to refuse vehicle delivery to customers who arrive late for pickup,
          lack the required reservation documents, or have insufficient credit card limits to cover the rental deposit.
        </b>
      </>
    ]
  }
};
