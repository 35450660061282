import { sendToDataLayer } from '@utils';

export enum HowToCancelContactType {
  CallCenter = 'callcenter',
  Line = 'line'
}
export const trackHowToCancelContact = (contactType: HowToCancelContactType, whichSection: 'edit' | 'cancel') => {
  sendToDataLayer(undefined, {
    event_category: 'content_howtocancel_section',
    event_action: 'contact_us',
    event_label: `${contactType}_${whichSection}`
  });
};

export const trackHowToCancelPaymentType = (paymentType: string, contentSection: string) => {
  sendToDataLayer(undefined, {
    event_category: `content_${contentSection}_section`,
    event_action: 'payment_type',
    event_label: `${paymentType}`
  });
};
